import { Injectable, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegistrationDialogComponent } from '@shared/registration-dialog/registration-dialog.component';
import { ClubSuntoryService } from '@shared/services/club-suntory.service';
@Injectable({
  providedIn: 'root',
})
export class JoinService implements OnInit {
  isClubSuntory: boolean;
  constructor(public dialog: MatDialog, private clubSuntorySvc: ClubSuntoryService) { }

  ngOnInit() {
    this.clubSuntorySvc.isRelevantUrl$.subscribe((isRelevant) => {
      this.isClubSuntory = isRelevant;
    });
  }

  openRegistration(params?): void {
    if (params.clubSuntory) {
      const dialogRef = this.dialog.open(RegistrationDialogComponent, {
        width: '420px',
        maxWidth: '98vw',
        height: '100vh',
        data: { queryParams: params },
        panelClass: ['registration-dialog', 'clubSuntory'],
      });
    } else {
      const dialogRef = this.dialog.open(RegistrationDialogComponent, {
        width: '420px',
        maxWidth: '90vw',
        data: { queryParams: params },
        panelClass: 'registration-dialog',
      });
    }

  }
}
