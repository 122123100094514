<!-- BVI -->
<ng-container *ngIf="!isClubSuntory">
  <div class="tb-card tb-card__news">
    <div class="media__figure" title="thumb image">
      <a [routerLink]="[link | localize, slug]">
        <img appLazyLoad [src]="img" alt="" width="1024" height="576" />
      </a>
      <!-- <div class="tb-card__label label text-uppercase fw-bold">
        <span>Category / Label</span>
      </div> -->
      <div class="tb-card__location mini text-uppercase" *ngIf="market">
        <img
          src="/assets/images/cards/location-icon.svg"
          class="location-icon"
          alt=""
          width="25"
          height="37"
        />
        <span [innerHTML]="market"></span>
      </div>
    </div>
    <h3 class="tb-card__title h5 text-uppercase">
      <a [routerLink]="[link | localize, slug]">
        {{ title }}
      </a>
    </h3>

    <div class="tb-card__description">
      <p [innerHTML]="teaser"></p>
    </div>
    <div class="tb-card__meta text-uppercase">
      <span class="tb-card__post-author mini" *ngIf="author">
        {{ 'Shared.NewsCard.By' | translate : { author: author } }}
      </span>
      <a [routerLink]="[link | localize, slug]" class="tb-card__link text-link"
        >Learn more <i> ></i>
      </a>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isClubSuntory">
  <div class="clubCard">
    <div class="clubCard__icon">
      <img src="./assets/images/ClubSuntory/event_icon.png" alt="" />
    </div>
    <div class="img-container">
      <div class="clubCard__waves"></div>
      <img [src]="img" alt="" class="img-fluid" />
    </div>
    <div class="card-title">
      <p [innerHTML]="title"></p>
    </div>
    <div class="card-location">
      <p class="label">Where</p>

      <p [innerHTML]="this.market"></p>
    </div>
    <div class="card-date">
      <p class="label">Date</p>

      <p [innerHTML]="eventDates"></p>
    </div>
    <div class="card-category">
      <p class="label">Category</p>
      <p [innerHTML]="category"></p>
    </div>
    <div class="card-buttons">
      <a [routerLink]="[link | localize, slug]">
        <button class="button-type outline">Learn More</button>
      </a>
      <a target="_blank" [href]="data.registerNow.uri">
        <button class="button-type">RSVP Now</button>
      </a>
    </div>
  </div>
</ng-container>
