import { Component, OnInit, Input } from '@angular/core';
import { NewsCardInterface, PageType } from '@shared/interfaces/interfaces';
import { INews, News, Event, Botw } from '@proxy/service-proxies';
import { ImgSafePathPipe, ImgPipe } from '@core/pipes/img.pipe';
import { get } from 'lodash';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { RoutingService } from '@routing/services/routing.service';
import { ConfigService, ConfigKeys } from '@shared/services/config.service';
import { ComponentBase } from '@core/classes/component-base';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})

// this.date = moment(this.newsArticle.date._d).format('MMM Do');
export class NewsCardComponent extends ComponentBase implements OnInit {
  @Input() data;
  @Input() iconColor;
  @Input() type: PageType;
  @Input() header: boolean;
  @Input() isClubSuntory:boolean;
  eventsPage: boolean;
  botwPage: boolean;
  perfectBlendPage: boolean;

  icon;
  public img;
  public isVirtualIcon: string;
  public category: string;
  public teaser: string;
  public author: string;
  public market: string;
  public slug: string;
  public title: string;
  public link: string;

  // Event Data
  public eventDates: string;
  public startDate;
  public startDay: string;
  public startMonth: string;
  public endDate;
  public endDay: string;
  public endMonth: string;

  campaign: string;
  campaignName: string;

  year;

  constructor(
    private imgSafePipe: ImgSafePathPipe,
    private imgPipe: ImgPipe,
    private router: Router,
    private routingService: RoutingService,
    private route: ActivatedRoute,
    private configSvc: ConfigService
  ) {
    super();
  }

  ngOnInit() {
    console.log(this.data);
    // this.eventsPage = this.router.url.includes('whats-on') ? true : false;
    this.eventsPage =
      this.type === PageType.Event || this.type === PageType.EventsLanding;
    // this.botwPage = this.router.url.includes('botw') ? true : false;
    this.botwPage = this.type === PageType.Botw;
    // this.perfectBlendPage = this.router.url.includes('perfect-blend') ? true : false;
    this.perfectBlendPage = this.type === PageType.PerfectBlend;

    this.campaign = this.route.snapshot.params.id;

    if (this.route.snapshot.params.campaign) {
      this.campaignName = this.route.snapshot.params.campaign;
    }

    this.iconSelection();
    // if (this.router.url.includes('/whats-on')) {
    if (this.eventsPage) {
      // Handle Event
      if (this.data instanceof Event) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `featuredImage[0].images[0].link.card_16_9`
        );
        this.teaser = get(this.data, `teaser.value`);
        this.slug = this.data.slug;
        this.title = get(this.data, `heading.value`);
        this.market = this.routingService.isGlobal()
          ? get(this.data, `locationMarket.name`)
          : get(this.data, `location.name`);
        this.category = get(this.data, `eventTopic.name`);
        this.link = '/whats-on';
        // Event Date setup
        this.eventDateComputation();
      } else if (this.data instanceof News) {
        this.img = this.imgSafePipe.transform(
          this.data,
          `featuredMedia[0]featuredMedia[0].images[0].link.card_16_9`
        );
        this.teaser = get(this.data, `teaser.value`);
        this.slug = this.data.slug;
        this.title = get(this.data, `heading`);
        this.market = this.routingService.isGlobal()
          ? get(this.data, `publishedMarket[0].name`)
          : get(this.data, `publishedMarket[0].name`);
        this.author = get(this.data, `author.personName`);
        this.link = '/news';
        this.eventsPage = false;
      }
    }
    // else if (this.router.url.includes('/botw')) {
    else if (this.type === PageType.Botw) {
      switch (this.data['_discriminator']) {
        case 'News':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredMedia[0]featuredMedia[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `teaser.value`);
          this.slug = this.data.slug;
          this.title = get(this.data, `heading`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `publishedMarket[0].name`)
            : get(this.data, `publishedMarket[0].name`);
          this.author = get(this.data, `author.personName`);
          this.link = '/news';
          if (this.data.category) {
            this.category = this.data.category.name;
          } else if (this.data['topic']) {
            this.category = this.data['topic'].name;
          } else {
            this.category = get(this.data, `category.name`);
          }
          break;
        case 'Event':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredImage[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `teaser.value`);
          this.slug = this.data.slug;
          this.title = get(this.data, `heading.value`);
          this.market = get(this.data, `locationMarket.name`)
            ? get(this.data, `locationMarket.name`)
            : get(this.data, `location.name`);
          this.category = get(this.data, `eventTopic.name`);
          this.link = '/whats-on';
          this.year = moment(get(this.data, `endDate`)).format('YYYY');
          break;
        case 'Botw':
          if (this.data.virtual) {
            this.unSub(
              this.configSvc.getConfiguration(ConfigKeys.BOTWVIcon)
            ).subscribe((data) => {
              this.isVirtualIcon = this.imgPipe.transform(data);
            });
          } else {
            this.unSub(
              this.configSvc.getConfiguration(ConfigKeys.BOTWIcon)
            ).subscribe((data) => {
              this.isVirtualIcon = this.imgPipe.transform(data);
            });
          }
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredImage[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `body.summary`);
          this.slug = get(this.data, `slug`);
          this.title = get(this.data, `heading.value`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `locationMarket.name`)
            : get(this.data, `location.name`);
          this.category = get(this.data, `topic.name`);
          this.link = '/botw';
          this.year = moment(get(this.data, `date`)).format('YYYY');
          break;
      }
    }
    // else if (this.router.url.includes('perfect-blend')) {
    else if (this.type === PageType.PerfectBlend) {
      switch (this.data['_discriminator']) {
        case 'News':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredMedia[0]featuredMedia[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `teaser.value`);
          this.slug = this.data.slug;
          this.title = get(this.data, `heading`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `publishedMarket[0].name`)
            : get(this.data, `publishedMarket[0].name`);
          this.author = get(this.data, `author.personName`);
          this.link = '/news';
          if (this.data.category) {
            this.category = this.data.category.name;
          } else if (this.data['topic']) {
            this.category = this.data['topic'].name;
          } else {
            this.category = get(this.data, `category.name`);
          }
          this.iconSelection();
          break;
        case 'Event':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredImage[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `teaser.value`);
          this.slug = this.data.slug;
          this.title = get(this.data, `heading.value`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `locationMarket.name`)
            : get(this.data, `location.name`);
          this.category = get(this.data, `eventTopic.name`);
          this.link = '/whats-on';
          // this.year = moment(get(this.data, `endDate`)).format('YYYY');
          this.eventDateComputation();
          this.iconSelection();
          break;
        case 'Botw':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredImage[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `body.summary`);
          this.slug = get(this.data, `slug`);
          this.title = get(this.data, `heading.value`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `locationMarket.name`)
            : get(this.data, `location.name`);
          this.category = get(this.data, `topic.name`);
          this.link = '/botw';
          this.year = moment(get(this.data, `date`)).format('YYYY');
          this.icon = this.imgSafePipe.transform(
            this.data,
            `topic.icon.images[0].link.card_16_9`
          );
          break;
      }
      // News Page
    } else if (this.type === PageType.BartendingEssentials) {
      this.img = this.imgSafePipe.transform(
        this.data,
        'heroSlides[0].image.images[0].link.card_16_9'
      );
      this.category = get(this.data, `topic.name`);
      this.teaser = get(this.data, `teaser.value`);
      this.author = get(this.data, `author.personName`);
      this.slug = this.data.slug;
      this.title = this.data.heading;
      this.link = '/community-resources/bar-essentials';
    } else {
      switch (this.data['_discriminator']) {
        case 'News':
          if (this.data.featuredMedia[0]['featuredMedia']) {
            this.img = this.imgSafePipe.transform(
              this.data,
              'featuredMedia[0]featuredMedia[0]images[0].link.card_16_9'
            );
          } else if (this.data.heroSlides[0].image) {
            this.img = this.imgSafePipe.transform(
              this.data,
              'heroSlides[0].image.images[0].link.card_16_9'
            );
          }

          this.category = get(this.data, `category.name`);
          this.teaser = get(this.data, `teaser.value`);
          this.author = get(this.data, `author.personName`);
          this.market = get(this.data, `publishedMarket[0].name`);
          this.slug = this.data.slug;
          this.title = this.data.heading;
          this.link = '/news';
          break;
        case 'ServiceWell':
          this.img = this.imgSafePipe.transform(
            this.data,
            'heroSlides[0]image.images[0].link.card_16_9'
          );
          this.category = get(this.data, `topic.name`);
          this.teaser = get(this.data, `teaser.value`);
          this.author = get(this.data, `author.personName`);
          // this.market = get(this.data, `publishedMarket[0].name`);
          this.slug = this.data.slug;
          this.title = this.data.heading;
          this.link = '/news/articles';
          break;
        case 'Event':
          this.img = this.imgSafePipe.transform(
            this.data,
            `featuredImage[0].images[0].link.card_16_9`
          );
          this.teaser = get(this.data, `teaser.value`);
          this.slug = this.data.slug;
          this.title = get(this.data, `heading.value`);
          this.market = this.routingService.isGlobal()
            ? get(this.data, `locationMarket.name`)
            : get(this.data, `location.name`);
          this.category = get(this.data, `eventTopic.name`);
          this.link = '/whats-on';
          // Event Date setup
          this.eventDateComputation();
          break;
      }
    }
  }

  iconSelection() {
    if (this.data instanceof News) {
      this.icon = get(this.data, `category.icon.images[0].url`);
    } else if (this.data instanceof Event) {
      this.icon = get(this.data, `eventTopic.icon.images[0].url`);
    } else if (this.data instanceof Botw) {
      this.icon = get(this.data, `topic.icon.images[0].url`);
    }
  }

  eventDateComputation() {
    this.startDay = moment(get(this.data, `startDate`)).format('Do');
    this.startDay =
      this.startDay.length > 3
        ? this.startDay.slice(0, 2)
        : this.startDay.slice(0, 1);
    this.endDay = moment(get(this.data, `endDate`)).format('Do');
    this.endDay =
      this.endDay.length > 3
        ? this.endDay.slice(0, 2)
        : this.endDay.slice(0, 1);
    this.startMonth = moment(get(this.data, `startDate`)).format('MMM');
    this.endMonth = moment(get(this.data, `endDate`)).format('MMM');
    if (this.startMonth === this.endMonth) {
      this.eventDates = `${this.startDay} - ${this.endDay} ${this.startMonth}`;
      if (this.startDay === this.endDay) {
        this.eventDates = `${this.startDay} ${this.startMonth}`;
      }
    } else {
      this.eventDates = `${this.startDay} ${this.startMonth} - ${this.endDay} ${this.endMonth}`;
    }
  }
}
