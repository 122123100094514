import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ClubSuntoryService {
  private isRelevantUrlSubject = new BehaviorSubject<boolean>(false);
  isRelevantUrl$: Observable<boolean> = this.isRelevantUrlSubject.asObservable();


  constructor(private router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {
    // Check the URL whenever the navigation ends
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateIsRelevantUrl();
    });

    // Initial check
    this.updateIsRelevantUrl();
  }

  private updateIsRelevantUrl(): void {
    this.isRelevantUrlSubject.next(this.fullUrlContainsRelevantKeywords());
  }

  private fullUrlContainsRelevantKeywords(): boolean {
    if (isPlatformBrowser(this.platform)) {
      let url = new URL(window.location.href);
      const hostname = url.hostname.toLowerCase();
      return hostname.includes('clubsuntory') || url.pathname.includes('en-au') || url.pathname.includes('en-nz');
    }
     else if (isPlatformServer(this.platform)) {
      // const url = new URL(this.request.url);
      // console.log(`url: ${url}, hostname: ${url.hostname}, pathname: ${url.pathname}`);
      // const hostname = url.hostname.toLowerCase();
      // return hostname.includes('clubsuntory') || url.pathname.includes('en-au') || url.pathname.includes('en-nz');
    }
    return false;
  }
}
