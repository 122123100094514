<div class="container-fluid" *ngIf="isClubSuntory === false">
  <header class="" #header>
    <!-- Logo -->

    <div class="tb_header__logo">
      <div class="hamburger">
        <input
          type="checkbox"
          [checked]="isChecked"
          id="menu_checkbox"
          (click)="toggleMenu()"
        />
        <label for="menu_checkbox">
          <div></div>
          <div></div>
          <div></div>
        </label>
      </div>
      <div class="d-none d-sm-flex">
        <a [routerLink]="['/' | localize]">
          <img
            width="200px"
            height="82px"
            src="/assets/images/the-blend-black.webp"
            alt=""
          />
        </a>
      </div>
      <div class="d-sm-none">
        <a [routerLink]="['/' | localize]">
          <img
            width="37px"
            height="50px"
            src="/assets/images/icons/blend-swirld-black.svg"
            alt=""
          />
        </a>
      </div>
    </div>
    <!-- End Logo -->
    <!-- Links -->
    <nav class="tb_header__links">
      <ul class="tb_nav__menu">
        <li
          #submenu
          class="tb_nav__item tb_nav__item--submenu"
          [ngClass]="{
            hasChild: item.children.length > 0 && !item.allPrivateLinks
          }"
          *ngFor="let item of navItems"
        >
          <a
            class="tb_nav__link"
            [routerLink]="[item.parent.path | localize]"
            *ngIf="(item.private && authenticated) || !item.private"
            tabindex="-1"
            (click)="closeMenu()"
            >{{ item.parent.name }}</a
          >
          <ng-container
            *ngIf="item.children.length > 0 && !item.allPrivateLinks"
          >
            <div
              class="submenu_arrow"
              (click)="submenu.classList.toggle('expanded')"
            >
              <img
                src="/assets/images/down_arrow_gold.webp"
                class="img-fluid"
              />
            </div>
          </ng-container>
          <div
            class="tb_nav__submenu"
            [ngClass]="{ privateKids: item.allPrivateLinks }"
            *ngIf="item.children.length > 0 && !item.allPrivateLinks"
          >
            <div class="submenu">
              <div class="link-container">
                <ng-container *ngFor="let child of item.children">
                  <a
                    class="tb_nav__submenu-link"
                    [ngClass]="{ hide: child.private && !authenticated }"
                    [routerLink]="[child.path | localize]"
                  >
                    {{ child.name }}
                  </a>
                </ng-container>
              </div>
              <!-- <div class="content-container">
                <h4 class="h4">Discover More</h4>
                <div class="row">
                  <a href="">
                    <div class="col-4">
                      <img class="img-fluid" src="https://picsum.photos/300/200" alt="" />
                    </div>
                    <div class="col-8">
                      <h6 class="h6">This is a mock title</h6>
                      <p>
                        Here is some mock content about absolutely nothing. O
                        hey look another sentence. How long does this go on for?
                      </p>
                      <a href="#" class="tb_link"> Learn More</a>
                    </div>
                  </a>
                </div>
              </div> -->
            </div>
          </div>
        </li>
      </ul>
    </nav>
    <!-- End Links -->
    <!-- Memeber -->
    <div class="tb_header__member">
      <!-- <div class="tb_input">
        <input class="tb_header__search--input" placeholder="Search" type="text">
      </div>

      <div class="tb_header__search" (click)="toggleSearch()">
        <img class="img-fluid" src="/assets/images/icons/search--gold.svg" alt="Search" />
      </div> -->
      <div id="list1" (click)="toggleModal($event)" class="tb_icon-select">
        <div
          class="tb_icon-select__options"
          (click)="stopPropagation($event)"
          [ngClass]="{ hasLanguages: isMultipleLanguages }"
          #marketSelector
        >
          <div class="marketSelector" *ngIf="currentMarket">
            <label for="market-select">Change Market</label>
            <select
              name=""
              id="market-select"
              (ngModelChange)="changeActiveMarket($event)"
              [(ngModel)]="currentMarket"
            >
              <!-- <option value="" disabled selected>{{currentMarket.name}}</option> -->
              <option
                *ngFor="let market of activeMarkets"
                [ngValue]="market"
                [selected]="market.id === currentMarket.id"
                [hidden]="market.id === currentMarket.id"
              >
                {{ market.name }}
              </option>
            </select>
          </div>
          <div
            class="languageSelector"
            *ngIf="isMultipleLanguages && activeLanguage"
          >
            <label for="lanuge-select">{{
              'Header.ChooseLanguage' | translate
            }}</label>
            <select
              name=""
              id="language-select"
              (ngModelChange)="changeActiveLanguage($event)"
              [(ngModel)]="activeLanguage"
            >
              <option
                [ngValue]="language"
                [selected]="language.id === activeLanguage.id"
                *ngFor="let language of languages"
              >
                {{ language.languageDisplay }}
              </option>
            </select>
          </div>
        </div>

        <!-- <div class="tb_icon-select__options" #marketSelector>
          <a class="tb_icon-select__option" href="/{{
							activeMarket.country.toLowerCase() === '00'
								? ''
								: activeMarket.defaultLanguage.languageCode +
								  '-' +
								  activeMarket.country.toLowerCase()
						}}" *ngFor="let activeMarket of activeMarkets" [attr.data-market]="activeMarket.country"
            (click)="changeActiveMarket(activeMarket, $event)"
            [ngClass]="{ hide: currentMarket.name === activeMarket.name }">
            <img [src]="imgSafePipe.transform(activeMarket, 'icon.images[0].url')" *ngIf="activeMarket.icon" />
            <span class="noFlag" *ngIf="!activeMarket.icon"></span>
            <span class="tb_icon-select__option-text">{{
							activeMarket.name
						}}</span>
          </a>
        </div> -->
        <div class="tb_icon-select__selected" id="arg" *ngIf="currentMarket">
          <img
            width="25px"
            height="25px"
            [src]="imgSafePipe.transform(currentMarket, 'icon.images[0].url')"
            *ngIf="currentMarket.icon"
          />
        </div>
      </div>
      <div class="tb_header__user">
        <div
          class="tb_navigation__account"
          *ngIf="(!showLanguageSwitcher && isMobile) || !isMobile"
          [ngClass]="{ public: !authenticated, member: authenticated }"
        >
          <a class="text-link" *ngIf="!authenticated">
            <span class="tb_navigation__account-text" (click)="openDialog()"
              >{{ 'Header.SignIn' | translate }}
            </span>
          </a>
          <span class="tb_navigation__account-break"></span>
          <div class="tb_navigation__account-logout" *ngIf="authenticated">
            <a class="text-link" (click)="routeAccountSettings()">{{
              'Header.AccountSettings' | translate
            }}</a>
            <a class="text-link" (click)="logOut($event)">{{
              'Header.LogOut' | translate
            }}</a>
          </div>
        </div>
        <div class="tb_navigation__join" *ngIf="!authenticated">
          <button class="button-type" (click)="openRegistration()">
            {{ 'Header.Join' | translate }}
          </button>
        </div>
      </div>
    </div>
    <!-- End Member -->
  </header>
</div>

<ng-container *ngIf="isClubSuntory">
  <header class="clubSuntory" #header [ngClass]="{ scrolled: isScrolled }">
    <div class="tb_header__member">
      <div class="tb_header__user">
        <div
          class="tb_navigation__account"
          *ngIf="(!showLanguageSwitcher && isMobile) || !isMobile"
          [ngClass]="{ public: !authenticated, member: authenticated }"
        >
          <a class="text-link" *ngIf="!authenticated">
            <span class="tb_navigation__account-text" (click)="openDialog()"
              >{{ 'Header.SignIn' | translate }}
            </span>
          </a>
          <span class="tb_navigation__account-break"></span>
          <div class="tb_navigation__account-logout" *ngIf="authenticated">
            <a
              class="text-link account-settings desktop"
              (click)="routeAccountSettings()"
              ><img src="/assets/images/ClubSuntory/user_icon.svg"
            /></a>
            <a
              class="text-link account-settings mobile"
              (click)="routeAccountSettings()"
              ><img src="/assets/images/ClubSuntory/user_icon.svg" />Account
              Settings</a
            >
            <a class="text-link logout" (click)="logOut($event)">{{
              'Header.LogOut' | translate
            }}</a>
          </div>
        </div>
        <div class="tb_navigation__join" *ngIf="!authenticated">
          <button class="button-type" (click)="openRegistration()">
            {{ 'Header.Join' | translate }}
          </button>
        </div>
      </div>
    </div>

    <!-- End Logo -->
    <!-- Links -->
    <nav class="tb_header__links">
      <div class="tb_nav__menu">
        <div class="home-link">
          <a [routerLink]="['/' | localize]">Home</a>
        </div>
        <div>
          <a [routerLink]="['/whats-on' | localize]">Events</a>
        </div>
        <div class="d-none-cs d-md-block-cs">
          <a class="logo-link" [routerLink]="['/' | localize]">
            <img src="/assets/images/ClubSuntory/Club_Suntory_Logo.svg"
          /></a>
        </div>
        <div>
          <a href="#">Suntory Cup</a>
        </div>
      </div>
    </nav>
    <!-- End Links -->
    <div class="csLogo">
      <div class="d-md-none-cs">
        <img src="/assets/images/ClubSuntory/small-blue.svg" />
      </div>
    </div>

    <div class="market-dropdown-container">
      <div class="market-dropdown d-none-cs d-md-block-cs">
        <span class="market-dropdown__caret"></span>
        <div class="selected-market" (click)="isDropdownOpen = !isDropdownOpen">
          {{ currentMarket?.name }}
        </div>
        <ul *ngIf="isDropdownOpen" class="market-list">
          <li
            *ngFor="let market of activeMarkets"
            (click)="selectMarket(market)"
          >
            {{ market.name }}
          </li>
        </ul>
      </div>
    </div>

    <div class="hamburger d-md-none-cs">
      <input
        type="checkbox"
        [checked]="isChecked"
        id="menu_checkbox"
        (click)="toggleMenu()"
      />
      <label for="menu_checkbox">
        <div></div>
        <div></div>
        <div></div>
      </label>
    </div>
  </header>
</ng-container>
