// Angular Imports
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, BrowserTransferStateModule, HammerModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

// Angular Material Imports
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MaterialModule } from './material/material.module';

// Core
import { AgeGateComponent } from '@core/components/age-gate/age-gate.component';
import { ComponentBase } from '@core/classes/component-base';
import { CookieBannerComponent } from './core/components/cookie-banner/cookie-banner.component';
import { DialogComponent } from '@core/components/dialog/dialog.component';
import { FooterComponent } from '@core/footer/footer.component';
import { HeaderComponent } from '@core/header/header.component';
import { ImgPipe, ImgSafePathPipe } from './core/pipes/img.pipe';
import { LocalizeRoutePipe } from '@core/pipes/localize-route.pipe';
import { PageComponentBase } from '@core/classes/page-component-base';
import { SignInComponent } from '@core/auth/components/sign-in/sign-in.component';
import { SingleAgeGateComponent } from '@core/components/single-age-gate/single-age-gate.component';

// Routing
import { RoutingModule } from '@routing/routing.module';

// Shared
import { SharedModule } from '@shared/shared.module';

// Misc
import { AppComponent } from './app.component';
import {
  BsiGtmModule,
  BsiSeoModule,
  Gtm,
  GtmService,
  SeoService,
} from 'bsi-toolkit';
import { BsiToolkitAgegateModule } from 'bsi-toolkit-agegate';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@env/environment';
import { LayoutComponent } from './layout/layout.component';
import { ServiceProxyModule } from '@proxy/service-proxy.module';
import { SharingDialogComponent } from '@modules/sharing-dialog/sharing-dialog.component';

/** 
 *  !Potential Removal
 import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
 import { SidenavListComponent } from './core/sidenav-list/sidenav-list.component';
 import { JoinModule } from '@modules/join/join.module';
 import { LoginModule } from '@modules/login/login.module';
 import { LearnModule } from '@modules/learn/learn.module';
 import { ContactUsModule } from '@modules/contact-us/contact-us.module';
 import { WelcomeDialogComponent } from '@modules/join/pages/join/welcome-dialog/welcome-dialog.component';
*  */

@NgModule({
    declarations: [
        // Components
        AgeGateComponent,
        AppComponent,
        CookieBannerComponent,
        DialogComponent,
        FooterComponent,
        HeaderComponent,
        LayoutComponent,
        SignInComponent,
        SingleAgeGateComponent,
        SharingDialogComponent,
        // Pipes
        ImgPipe,
        ImgSafePathPipe,
        LocalizeRoutePipe,
        // Base
        ComponentBase,
        PageComponentBase,
        /**
         *  !Potential Removal
          SidenavListComponent,
          WelcomeDialogComponent,
         *  */
    ],
    imports: [
        // Angular Material
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatTabsModule,
        MaterialModule,
        // Angular
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SharedModule,
        // Routing
        BrowserModule.withServerTransition({ appId: 'my-app' }),
        RoutingModule,
        // Bsi
        BsiGtmModule.forRoot({ siteType: Gtm.siteType.standard, brand: '' }),
        BsiSeoModule.forRoot({ BaseURL: environment.baseUrl }),
        // Misc
        BrowserTransferStateModule,
        BsiToolkitAgegateModule,
        ServiceProxyModule,
        HammerModule,
        /**
         *  !Potential Removal
        JoinModule,
        LoginModule,
        LearnModule,
        ContactUsModule,
        NgbModule,
         *  */
    ],
    exports: [ImgPipe, ImgSafePathPipe],
    providers: [CookieService, ImgPipe, ImgSafePathPipe],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private gtm: GtmService, private seo: SeoService) {}
}
