import {
  Component,
  OnInit,
  ChangeDetectorRef,
  Input,
  AfterViewInit,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
// import { HeroInterface } from '@shared/interfaces/interfaces';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ImgPipe, ImgSafePathPipe } from '@core/pipes/img.pipe';
import {
  Event,
  News,
  Hero,
  Botw,
  TPB,
  Image,
  PFeaturedMedia,
  ServiceWell,
  Media,
  Base,
} from '@proxy/service-proxies';
import {
  GenericHeroInterface,
  ImgResizeObject,
} from '@shared/interfaces/interfaces';
import { get } from 'lodash';
import { LinkService } from '@shared/services/link.service';
import { ImgResizeType, ImgSizes } from '@core/enum/enum';

enum SlideTypes {
  Image = 'img',
  Video = 'video',
}

class HeroSlide {
  public bgImg;
  public fileType: SlideTypes;
  public heading: string;
  public text: string;
  public route: string;
  public link: string | any[];
  public src: string;

  updatedLink;

  private Types = SlideTypes;

  constructor(
    slide:
      | Event
      | News
      | Hero
      | Botw
      | TPB
      | Image
      | GenericHeroInterface
      | PFeaturedMedia
      | ServiceWell,
    private imgSafePipe: ImgSafePathPipe,
    private linkService: LinkService
  ) {
    if (slide instanceof Event) {
      this.setEvent(slide);
    } else if (slide instanceof News) {
      this.setNews(slide);
    } else if (slide instanceof Hero) {
      this.setHero(slide);
    } else if (slide instanceof TPB) {
      this.setTPB(slide);
    } else if (slide instanceof Image) {
      this.setImage(slide);
    } else if (slide instanceof Botw) {
      this.setBotw(slide);
    } else if (slide instanceof PFeaturedMedia) {
      this.setFeaturedMedia(slide);
    } else if (slide instanceof ServiceWell) {
      this.setServiceWell(slide);
    } else {
      this.setGeneric(slide);
    }
  }

  private setEvent(slide: Event) {
    console.log(slide);
    // this.bgImg = this.imgSafePipe.transform(slide, `featuredImage[0].images[0].url`, false);
    this.bgImg = get(slide, `featuredImage[0]`);
    this.fileType = slide.video ? this.Types.Video : this.Types.Image;
    this.heading = slide.heading.value;
    this.text = slide.teaser.value;
    this.route = slide.slug;
    this.link = 'whats-on';
    this.src = slide.video;
  }

  private setFeaturedMedia(slide: PFeaturedMedia) {
    // this.bgImg = this.imgSafePipe.transform(slide, `featuredMedia[0].featuredMedia[0].images[0].url`, false);
    this.bgImg = get(slide, `featuredMedia[0].featuredMedia[0]`);
    this.fileType = this.Types.Image;
  }

  private setNews(slide: News) {
    if (slide.featuredMedia[0]['featuredMedia']) {
      this.bgImg = get(slide, `featuredMedia[0].featuredMedia[0]`);
      // this.bgImg = this.imgSafePipe.transform(
      //   slide,
      //   'featuredMedia[0]featuredMedia[0]images[0].url'
      // );
    } else if (slide.heroSlides[0]['image']) {
      this.bgImg = get(slide, `heroSlides[0].image`);
      // this.bgImg = this.imgSafePipe.transform(
      //   slide,
      //   'heroSlides[0].image.images[0].url'
      // );
    }
    // this.bgImg = this.imgSafePipe.transform(
    //   slide,
    //   `featuredMedia[0].featuredMedia[0].images[0].url`,
    //   false
    // );
    this.fileType = slide.video ? this.Types.Video : this.Types.Image;
    this.heading = slide.heading;
    this.text = slide.teaser.value;
    this.route = slide.slug;
    this.link = 'news';
    this.src = slide.video;
  }

  private setHero(slide: Hero) {
    // this.bgImg = this.imgSafePipe.transform(slide, `image.images[0].url`, false);
    this.bgImg = get(slide, `image`);
    this.fileType = slide.video ? this.Types.Video : this.Types.Image;
    this.heading = slide.heading;
    this.text = slide.description.value;
    this.src = slide.video;
    if (slide.page) {
      this.updatedLink = this.linkService.getLink(slide.page).link;
    }
  }

  private setTPB(slide: TPB) {
    // this.bgImg = this.imgSafePipe.transform(slide, `featuredImage[0].images[0].url`, false);
    this.bgImg = get(slide, `featuredImage[0]`);
    this.fileType = slide.video ? this.Types.Video : this.Types.Image;
    this.heading = slide.heading.value;
    this.text = slide.body.value;
    this.route = slide.slug;
    this.link = 'perfect-blend';
    this.src = slide.video;
  }
  private setServiceWell(slide: ServiceWell) {
    // this.bgImg = this.imgSafePipe.transform(
    //   slide,
    //   `heroSlides[0].image.images[0].url`
    // );
    this.bgImg = get(slide, `heroSlides[0].image`);

    this.fileType = slide.heroSlides[0]['video']
      ? this.Types.Video
      : this.Types.Image;
    this.src = get(slide, `heroSlides[0]['video']`);
    this.heading = slide.heading;
    this.text = slide.teaser.value;
    this.link = 'news/articles';
    this.route = slide.slug;
  }

  private setImage(slide: Image) {
    // this.bgImg = this.imgSafePipe.transform(slide, `images[0].url`, false);
    this.bgImg = slide;
    this.fileType = this.Types.Image;
    // heading: item['heading'].value,
    // text: item.body.summary,
    // link: '/botw',
    // route: item['slug']
  }

  private setBotw(slide: Botw) {
    // this.bgImg = this.imgSafePipe.transform(slide, `featuredImage[0].images[0].url`, false);
    this.bgImg = get(slide, `featuredImage[0]`);
    this.fileType = slide.video ? this.Types.Video : this.Types.Image;
    this.heading = slide['heading'].value;
    this.text = slide.body.summary;
    this.link = '/botw';
    this.route = slide['slug'];
    this.src = slide.video;
  }

  private setGeneric(slide: GenericHeroInterface) {
    this.bgImg = get(slide, `bgImg[0]`);
    this.fileType =
      slide.fileType === 'Image' ? this.Types.Image : this.Types.Video;
    this.src = slide.src;
    this.text = slide.text;
    this.heading = slide.heading;
  }
}

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() data;
  // Newer input to handle passing in objects (news, events, static-hero, etc...)
  @Input() slides: (Event | News | Hero | TPB | Image | Botw | ServiceWell)[];
  @Input() slide: Event | News | Hero | TPB | Image | Botw | ServiceWell;
  @Input() themeColor: string;
  @Input() component: string;
  @Input() isClubSuntory: boolean;
  @Input() isEventsPage: boolean;
  route;
  // slides;
  closeResult: string;
  slideNo = 0;

  public Types = SlideTypes;

  withAnim = true;
  resetAnim = true;

  dataRecieved: boolean = false;

  isHome: boolean = false;

  @ViewChild('myCarousel', { static: false }) myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig;

  public imgResizeObject: ImgResizeObject;
  constructor(
    private cdr: ChangeDetectorRef,
    public imgPipe: ImgPipe,
    private modalService: NgbModal,
    private imgSafePipe: ImgSafePathPipe,
    private linkService: LinkService
  ) {}

  ngOnInit() {
    this.imgResizeObject = {
      small: ImgSizes.HeroNineSixteen,
      medium: ImgSizes.HeroNineSixteen,
      large: ImgSizes.HeroSixteenNine,
      xlarge: ImgSizes.HeroSixteenNine,
      imgType: ImgResizeType.Hero,
    };
    // for setting container-fluid class if on home page

    if (this.component === 'HomeComponent') {
      this.isHome = true;
    }
  }

  private initialize() {
    // Need a way to create a dynamic route between news and events when events are available.
    if (this.slides || this.slide) {
      this.transformSlides();
    }

    this.carouselConfig = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: true,
      },
      speed: 400,
      interval: {
        timing: 4000,
      },
      load: 2,
      touch: true,
      loop: true,
      easing: 'cubic-bezier(0, 0, 0.2, 1)',
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, `slides.currentValue`)) {
      // handle slides input
      this.slides = changes.slides.currentValue;
      this.initialize();
    } else if (get(changes, `slide.currentValue`)) {
      // handle slidE input
      this.slide = changes.slide.currentValue;
      this.initialize();
    }
  }

  private transformSlides() {
    console.log(this.slides);
    this.dataRecieved = true;
    this.data = { slides: [] };
    if (this.slide) {
      this.data.slides.push(
        new HeroSlide(this.slide, this.imgSafePipe, this.linkService)
      );
    } else {
      this.slides?.forEach((slide) => {
        this.data.slides.push(
          new HeroSlide(slide, this.imgSafePipe, this.linkService)
        );
      });
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  open(vidName) {
    this.modalService
      .open(vidName, {
        ariaLabelledBy: 'modal-basic-title',
        size: 'lg',
        centered: true,
        windowClass: 'mainVid',
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
