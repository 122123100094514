<ng-container *ngIf="isClubSuntory === false || isClubSuntory === true">
  <bsi-gtm *ngIf="loadGtm"></bsi-gtm>
  <!-- <app-cookie-banner></app-cookie-banner> -->
  <app-ie-check *ngIf="isIe"></app-ie-check>
  <app-age-gate *ngIf="isBrowser"></app-age-gate>
  <app-single-age-gate *ngIf="isBrowser"></app-single-age-gate>
  <app-header></app-header>
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="loadingBoolean"
  ></mat-progress-bar>
  <main>
    <div class="tb_stage">
      <!-- container-fluid -->
      <router-outlet></router-outlet>
    </div>
  </main>
  <app-footer></app-footer>
</ng-container>
