import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { VisibilityService } from '@shared/services/visibility.service';
import { take, filter } from 'rxjs/operators';
@Directive({
  selector: '[visibleWith]',
})
export class ContentLazyLoadDirective implements AfterViewInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private visibilityService: VisibilityService,
    @Inject(PLATFORM_ID) private platformId: Object // Inject platform ID
  ) { }
  // This directive is built for lazy loading components on pages
  @Input()
  set visibleWith(element) {
    if (isPlatformBrowser(this.platformId)) {
      this.visibilityService
        .elementInSight(new ElementRef(element))
        .pipe(
          filter((visible) => visible),
          take(1)
        )
        .subscribe(() => {
          this.viewContainer.createEmbeddedView(this.templateRef);
        });
    }
  }

  ngAfterViewInit() { }
}
