import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRegistrationComponent } from '@shared/user-registration/user-registration.component';
import { ClubSuntoryService } from '@shared/services/club-suntory.service';
@Component({
  selector: 'app-registration-dialog',
  templateUrl: './registration-dialog.component.html',
  styleUrls: ['./registration-dialog.component.scss'],
})
export class RegistrationDialogComponent implements OnInit {
  @ViewChild('register', { static: true }) signin: UserRegistrationComponent;
  isClubSuntory: boolean;
  constructor(
    public dialogRef: MatDialogRef<UserRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private clubSuntorySvc: ClubSuntoryService
  ) { }

  ngOnInit(): void {

    this.clubSuntorySvc.isRelevantUrl$.subscribe((isRelevant) => {
      this.isClubSuntory = isRelevant;
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
