<ng-container *ngIf="isClubSuntory === false">
  <div class="tb_surface tb_surface--light-gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="tb_bvi_footer">
            <div class="tb_bvi_footer__section">
              <div class="tb_bvi_footer__section--top-container">
                <div class="tb_bvi_footer__logo">
                  <a href="/" class="tb_bvi_footer__logo-link">
                    <img
                      width="200px"
                      height="82px"
                      src="/assets/images/the-blend-black.webp"
                      title="The Blend"
                    />
                  </a>
                </div>
                <!-- <div class="tb_bvi_footer__subs">
                <ul class="tb_bvi_footer__subs-menu">
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link" href="">Subnavigation</a>
                  </li>
                  <li class="tb_bvi_footer__subs-item">
                    <a class="tb_bvi_footer__subs-link"
                      [routerLink]="['/contact-us' | localize]">{{ 'Footer.Contact' | translate }}</a>
                  </li>
                </ul>
              </div> -->
              </div>
              <div class="tb_bvi_footer__section--middle-container">
                <div class="tb_bvi_footer__section--right-container">
                  <div class="tb_bvi_footer__social-container">
                    <div class="tb_bvi_footer--headline">
                      {{ 'Footer.FollowUs' | translate }}
                    </div>
                    <ul class="tb_bvi_footer__social-menu">
                      <li
                        *ngIf="fbLink != ''"
                        class="tb_bvi_footer__social-item"
                      >
                        <a
                          class="tb_bvi_footer__social-link"
                          target="_blank"
                          [href]="fbLink"
                        >
                          <span class="tb_bvi_footer__social-icon"
                            ><img
                              width="32px"
                              height="32px"
                              src="/assets/images/bvi_icons/facebook-color.svg" /></span
                        ></a>
                      </li>
                      <!-- <li *ngIf="twitterLink != ''" class="tb_bvi_footer__social-item">
                      <a class="tb_bvi_footer__social-link" target="_blank" [href]="twitterLink">
                        <span class="tb_bvi_footer__social-icon"><img width="32px" height="32px"
                            src="/assets/images/bvi_icons/twitter-color.svg" /></span></a>
                    </li> -->
                      <li
                        *ngIf="instagramLink != ''"
                        class="tb_bvi_footer__social-item"
                      >
                        <a
                          class="tb_bvi_footer__social-link"
                          target="_blank"
                          [href]="instagramLink"
                        >
                          <span class="tb_bvi_footer__social-icon"
                            ><img
                              width="32px"
                              height="32px"
                              src="/assets/images/bvi_icons/instagram-color.svg" /></span
                        ></a>
                      </li>
                      <li
                        *ngIf="youtubeLink != ''"
                        class="tb_bvi_footer__social-item"
                      >
                        <a
                          class="tb_bvi_footer__social-link"
                          target="_blank"
                          [href]="youtubeLink"
                          ><span class="tb_bvi_footer__social-icon"
                            ><img
                              width="32px"
                              height="32px"
                              src="/assets/images/bvi_icons/youtube-color.svg" /></span
                        ></a>
                      </li>
                    </ul>
                  </div>
                  <div class="tb_bvi_footer__market-container">
                    <div class="tb_bvi_footer--headline">
                      {{
                        'PerfectBlend.Registration.PreliminaryPage.ChooseRegion'
                          | translate
                      }}
                    </div>
                    <div
                      class="tb_bvi_footer__market--select__container"
                      *ngIf="activeMarkets"
                    >
                      <div
                        (click)="showList($event)"
                        class="tb_bvi_footer__market--select"
                      >
                        <div class="tb_bvi_footer__market--options">
                          <a
                            class="tb_bvi_footer__market"
                            href="/{{
                              activeMarket.country.toLowerCase() === '00'
                                ? ''
                                : activeMarket.defaultLanguage.languageCode +
                                  '-' +
                                  activeMarket.country.toLowerCase()
                            }}"
                            *ngFor="let activeMarket of activeMarkets"
                            [attr.data-market]="activeMarket.country"
                            (click)="changeActiveMarket(activeMarket, $event)"
                            [ngClass]="{
                              hide: currentMarket.name === activeMarket.name
                            }"
                          >
                            <span
                              class="tb_bvi_footer__market--select__option-text"
                            >
                              {{ activeMarket.name }}</span
                            >
                          </a>
                        </div>
                        <div
                          class="tb_bvi_footer__market--selected"
                          *ngIf="currentMarket"
                        >
                          <span class="tb_bvi_footer__market--selected-text">
                            {{ currentMarket.name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tb_bvi_footer__menu-container">
                  <div class="tb_bvi_footer__menu">
                    <ul class="tb_bvi_footer__menu-list">
                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          *ngIf="termLink"
                          target="_blank"
                          href="{{ termLink }}"
                          >{{ 'Footer.Terms' | translate }}</a
                        >
                      </li>
                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          *ngIf="privacyLink"
                          target="_blank"
                          href="{{ privacyLink }}"
                          >{{ 'Footer.Privacy' | translate }}</a
                        >
                      </li>

                      <li class="tb_bvi_footer__menu-item">
                        <!-- OneTrust Cookies Settings button start ot-sdk-show-settings-->
                        <!-- <a id="ot-sdk-btn" class=" tb_bvi_footer__menu-link">{{ 'Footer.DoNotSell' | translate }}</a>  -->

                        <a
                          class="ot-sdk-show-settings tb_bvi_footer__menu-link"
                          href="javascript:void(0);"
                          >{{ 'Footer.DoNotSell' | translate }}</a
                        >
                        <!-- OneTrust Cookies Settings button end -->
                        <!-- <a href="" class="optanon-toggle-display" target="_self">Do Not Sell My Personal Information</a> -->
                      </li>

                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          target="_blank"
                          href="{{ cookieLink }}"
                          >{{ 'Footer.Cookie' | translate }}</a
                        >
                      </li>
                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          target="_blank"
                          href="{{ drinksmartLink }}"
                          >{{ 'Footer.DrinkResponsibly' | translate }}</a
                        >
                      </li>
                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          [routerLink]="['/sitemap' | localize]"
                          >{{ 'Footer.Sitemap' | translate }}</a
                        >
                      </li>
                      <li class="tb_bvi_footer__menu-item">
                        <a
                          class="tb_bvi_footer__menu-link"
                          [routerLink]="['/contact-us' | localize]"
                          >{{ 'Footer.Contact' | translate }}</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tb_surface--gray">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="tb_bvi_footer__section--bottom-container">
            <div class="tb_bvi_footer__copyright-container">
              <p class="tb_footer__address-content">
                {{ 'Footer.Copyright' | translate : { year: year } }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isClubSuntory">
  <footer class="clubSuntory">
    <div class="container-fluid">
      <div class="row footer-top">
        <div class="footer-top__left">
          <div class="col-auto">
            <img src="./assets/images/ClubSuntory/Club_Suntory_Logo.svg" />
          </div>
        </div>
        <div class="footer-top__right">
          <ul>
            <li><a [routerLink]="['/' | localize]">Home</a></li>
            <li><a [routerLink]="['/whats-on' | localize]">Events</a></li>
            <li><a> Suntory Cup</a></li>
            <li><a [routerLink]="['/contact-us' | localize]">Contact Us</a></li>
            <li><a href="#">About Suntory</a></li>
          </ul>
        </div>
      </div>
      <hr />
      <div class="footer-bottom row">
        <div class="col-12 col-md-4 col-lg-6">
          <div class="footer-bottom__left">
            <ul class="">
              <li class="">
                <a
                  class=""
                  *ngIf="termLink"
                  target="_blank"
                  href="{{ termLink }}"
                  >{{ 'Footer.Terms' | translate }}</a
                >
              </li>
              <li class="">
                <a
                  class=""
                  *ngIf="privacyLink"
                  target="_blank"
                  href="{{ privacyLink }}"
                  >{{ 'Footer.Privacy' | translate }}</a
                >
              </li>

              <li class="">
                <a class="ot-sdk-show-settings" href="javascript:void(0);">{{
                  'Footer.DoNotSell' | translate
                }}</a>
              </li>

              <li class="">
                <a class="" target="_blank" href="{{ cookieLink }}">{{
                  'Footer.Cookie' | translate
                }}</a>
              </li>
              <li class="">
                <a class="" target="_blank" href="{{ drinksmartLink }}">{{
                  'Footer.DrinkResponsibly' | translate
                }}</a>
              </li>
              <li class="">
                <a class="" [routerLink]="['/sitemap' | localize]">{{
                  'Footer.Sitemap' | translate
                }}</a>
              </li>
              <li class="">
                <a class="" [routerLink]="['/contact-us' | localize]">{{
                  'Footer.Contact' | translate
                }}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-md-8 col-lg-6">
          <div class="footer-bottom__right">
            <div class="footer__social">
              <div class="footer__follow">
                <p>{{ 'Footer.FollowUs' | translate }}</p>
              </div>
              <ul>
                <li>
                  <a target="_blank" [href]="fbLink"
                    ><img
                      width="32px"
                      height="32px"
                      src="/assets/images/ClubSuntory/facebook_icon.svg"
                  /></a>
                </li>
                <!-- <li>
                  <a target="_blank" href="#"
                    ><img
                      width="32px"
                      height="32px"
                      src="/assets/images/ClubSuntory/twitter_icon.svg"
                  /></a>
                </li> -->
                <li>
                  <a target="_blank" [href]="instagramLink"
                    ><img
                      width="32px"
                      height="32px"
                      src="/assets/images/ClubSuntory/instagram_icon.svg"
                  /></a>
                </li>
                <li>
                  <a target="_blank" [href]="youtubeLink"
                    ><img
                      width="32px"
                      height="32px"
                      src="/assets/images/ClubSuntory/youtube_icon.svg"
                  /></a>
                </li>
              </ul>
            </div>
            <div class="footer__region">
              <label for="regionDropdown">Choose Language / Region</label>
              <div class="market-dropdown">
                <span class="market-dropdown__caret"></span>
                <div
                  class="selected-market"
                  (click)="isDropdownOpen = !isDropdownOpen"
                >
                  {{ currentMarket?.name }}
                </div>
                <ul *ngIf="isDropdownOpen" class="market-list">
                  <li
                    *ngFor="let market of activeMarkets"
                    (click)="selectMarket(market)"
                  >
                    {{ market.name }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tb_surface--dark-gray">
      <p class="tb_footer__address-content">
        {{ 'Footer.Copyright' | translate : { year: year } }}
      </p>
    </div>
  </footer>
</ng-container>
