<!-- <div class="tb_template" style="padding:1rem">
  <div class="tb_template__section justify-content-center" style="padding:0">
    <div class="wrapper-centered-text">
      <div class="tb_type-group tb_type-group--centered" [ngClass]="{'tb_type-group--reverse' : reverse}">
        <h2 class="tb_type-group__heading" *ngIf="!header" [innerHTML]="data?.heading"></h2>
        <h1 class="tb_type-group__heading" *ngIf="header" [innerHTML]="data?.heading"></h1>
        <p class="tb_type-group__copy" [innerHTML]="data?.copy"></p>
      </div>
    </div>
  </div>
</div> -->

<!-- BVI -->
<div class="tb_rich-text" [ngClass]="{ centered: isCenter, reverse: reverse }">
  <!-- <p class="section-headline">Category Title</p> -->
  <h1 class="h1" [innerHTML]="data?.heading"></h1>
  <!-- <p class="paragraph-subheadline" [innerHTML]="data?.subHeadline"></p> -->
  <p class="copy" [innerHTML]="data?.copy" *ngIf="data?.copy"></p>
</div>
