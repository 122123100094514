import { NgModule } from '@angular/core';
import { NewsCardComponent } from './news-card/news-card.component';
import { CategoryCardComponent } from './category-card/category-card.component';
import { SpotlightCardComponent } from './spotlight-card/spotlight-card.component';
import { CommonModule } from '@angular/common';
import { HeroComponent } from './hero/hero.component';
import { IconCardComponent } from './icon-card/icon-card.component';
import { CenteredTextComponent } from './centered-text/centered-text.component';
import { RouterModule } from '@angular/router';
import { SubheadComponent } from './subhead/subhead.component';
import { TwoUpComponent } from './two-up/two-up.component';
import { CtaSectionComponent } from './cta-section/cta-section.component';
import { NguCarouselModule } from '@ngu/carousel';
import { ProductCardCarouselComponent } from './product-card-carousel/product-card-carousel.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { CarouselCardComponent } from './carousel-card/carousel-card.component';
import { TimelineComponent } from './timeline/timeline.component';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SafePipe } from './safe.pipe';
import { TextTruncatePipe } from './text-truncate.pipe';
import { SurfaceSectionComponent } from './surface-section/surface-section.component';
import { TemplateSectionComponent } from './template-section/template-section.component';
import { DeletedCountryFilter } from '@modules/join/phone-county-codes.pipe';
import { BrandCardComponent } from './brand-card/brand-card.component';
import { CocktailCardComponent } from './cocktail-card/cocktail-card.component';
// import { CocktailDetailCardComponent } fro../modules/cocktail/components/cocktail-detail-card/cocktail-detail-card.componentent';
import { ProductDetailCardComponent } from './product-detail-card/product-detail-card.component';
import { ExploreProductsCardComponent } from './explore-products-card/explore-products-card.component';
import { LoadingIconComponent } from './loading-icon/loading-icon.component';
import { MediaCarouselComponent } from './media-carousel/media-carousel.component';
import { SsoCheckDirective } from './directives/sso-check.directive';
import { PasswordComponent } from './password/password.component';
// import {
//   // MatDialogModule,
//   // MatInputModule,
//   // MatFormFieldModule,
//   MatProgressBarModule
// } from '@angular/material/';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { CtaSectionPerfectBlendComponent } from './cta-section-perfect-blend/cta-section-perfect-blend.component';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { BasicPageComponent } from './dynamic/basic-page/basic-page.component';
import { HostDirective } from './directives/host.directive';
import { LinkComponent } from './dynamic/components/link/link.component';
import { DynamicWrapperComponent } from './dynamic/components/dynamic-wrapper/dynamic-wrapper.component';
import { OuterWrapperComponent } from './dynamic/components/outer-wrapper/outer-wrapper.component';
import { ReplaceTagDirective } from './dynamic/directives/replace-tag.directive';
import { TagWrapperComponent } from './dynamic/components/tag-wrapper/tag-wrapper.component';
import { SanitizePipe } from './dynamic/pipes/sanitize.pipe';
import { ImgUrlPipe } from './pipes/img-url.pipe';
import { CocktailDetailCardComponent } from './cocktail-detail-card/cocktail-detail-card.component';
import { CocktailIngredientsComponent } from './cocktail-ingredients/cocktail-ingredients.component';
import { TwoUpMediaComponent } from './two-up/components/media/media.component';
import { TwoUpLinkComponent } from './two-up/components/link/link.component';
import { TwoUpContentComponent } from './two-up/components/two-up-content/two-up-content.component';
import { UploadComponent } from './upload/upload.component';
import { MultiUploadComponent } from './multi-upload/multi-upload.component';
import { PodcastComponent } from './podcast/podcast.component';
import { JobCardComponent } from './job-card/job-card.component';
import { HiveCardComponent } from './hive-card/hive-card.component';
import { ContentComponent } from './content/content.component';
import { FeaturedContentComponent } from './featured-content/featured-content.component';
import { CarouselComponent } from './carousel/carousel.component';
import { PodcastSeriesComponent } from './podcast-series/podcast-series.component';
import { FinalistsComponent } from './finalists/finalists.component';
import { ServiceDirectoryCardComponent } from './service-directory-card/service-directory-card.component';
import { BooksCardComponent } from './books-card/books-card.component';
import { TimeSincePipe } from './pipes/time-since.pipe';
import { ThousandTranformPipe } from './pipes/thousand-tranform.pipe';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { EmailShareDirective } from './directives/email-share.directive';
import { IeCheckComponent } from './ie-check/ie-check.component';
import { SingleLoginComponent } from './single-login/single-login.component';
import { MatTabsModule } from '@angular/material/tabs';
import { SearchPipe } from './pipes/search.pipe';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ImgCarouselComponent } from './img-carousel/img-carousel.component';
import { HammerModule, HammerGestureConfig } from '@angular/platform-browser';
import { ImgResizeDirective } from './directives/img-resize.directive';
import { LazyLoadDirective } from './directives/lazy-load.directive';
import { SelectionCardComponent } from './selection-card/selection-card.component';
import { SubNaviComponent } from './sub-navi/sub-navi.component';
import { HomeSpotlightCardComponent } from './home-spotlight-card/home-spotlight-card.component';
import { ContentLazyLoadDirective } from './directives/content-lazy-load.directive';
import { VisibilityService } from './services/visibility.service';
import { RegistrationDialogComponent } from './registration-dialog/registration-dialog.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';
import { FinishedDialogComponent } from './user-registration/components/finished-dialog/finished-dialog.component';
import { FlickerComponent } from './flicker/flicker.component';
import { AmbassadorCardComponent } from './ambassador-card/ambassador-card.component';

// import * as hammer from "hammerjs";

// export class MyHammerConfig extends HammerGestureConfig {
//   overrides = <any>{
//     swipe: { direction: hammer.DIRECTION_HORIZONTAL },
//     pinch: { enable: false },
//     rotate: { enable: false }
//   };
// }

@NgModule({
    declarations: [
        NewsCardComponent,
        CategoryCardComponent,
        SpotlightCardComponent,
        HeroComponent,
        IconCardComponent,
        CenteredTextComponent,
        SubheadComponent,
        TwoUpComponent,
        CtaSectionComponent,
        ProductCardCarouselComponent,
        ProductCardComponent,
        CarouselCardComponent,
        TimelineComponent,
        SnackbarComponent,
        // DialogComponent
        SafePipe,
        TextTruncatePipe,
        SurfaceSectionComponent,
        TemplateSectionComponent,
        DeletedCountryFilter,
        BrandCardComponent,
        CocktailCardComponent,
        CocktailDetailCardComponent,
        CocktailIngredientsComponent,
        ProductDetailCardComponent,
        ExploreProductsCardComponent,
        LoadingIconComponent,
        MediaCarouselComponent,
        SsoCheckDirective,
        PasswordComponent,
        CtaSectionPerfectBlendComponent,
        StripHtmlPipe,
        BasicPageComponent,
        HostDirective,
        LinkComponent,
        DynamicWrapperComponent,
        OuterWrapperComponent,
        ReplaceTagDirective,
        TagWrapperComponent,
        SanitizePipe,
        ImgUrlPipe,
        TwoUpMediaComponent,
        TwoUpLinkComponent,
        TwoUpContentComponent,
        UploadComponent,
        MultiUploadComponent,
        PodcastComponent,
        JobCardComponent,
        HiveCardComponent,
        ContentComponent,
        FeaturedContentComponent,
        CarouselComponent,
        PodcastSeriesComponent,
        FinalistsComponent,
        ServiceDirectoryCardComponent,
        BooksCardComponent,
        TimeSincePipe,
        ThousandTranformPipe,
        AutocompleteComponent,
        EmailShareDirective,
        IeCheckComponent,
        SingleLoginComponent,
        SearchPipe,
        BreadcrumbsComponent,
        ImgCarouselComponent,
        ImgResizeDirective,
        LazyLoadDirective,
        SelectionCardComponent,
        SubNaviComponent,
        HomeSpotlightCardComponent,
        ContentLazyLoadDirective,
        RegistrationDialogComponent,
        UserRegistrationComponent,
        FinishedDialogComponent,
        FlickerComponent,
        AmbassadorCardComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        LocalizeRouterModule.forChild([]),
        NguCarouselModule,
        // MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressBarModule,
        TranslateModule,
        MatTabsModule,
        HammerModule,
    ],
    exports: [
        NewsCardComponent,
        CategoryCardComponent,
        SpotlightCardComponent,
        HeroComponent,
        IconCardComponent,
        CenteredTextComponent,
        SubheadComponent,
        TwoUpComponent,
        CtaSectionComponent,
        ProductCardCarouselComponent,
        ProductCardComponent,
        CarouselCardComponent,
        TimelineComponent,
        SnackbarComponent,
        SafePipe,
        TextTruncatePipe,
        SurfaceSectionComponent,
        TemplateSectionComponent,
        DeletedCountryFilter,
        BrandCardComponent,
        CocktailCardComponent,
        CocktailDetailCardComponent,
        CocktailIngredientsComponent,
        ProductDetailCardComponent,
        ExploreProductsCardComponent,
        LoadingIconComponent,
        MediaCarouselComponent,
        SsoCheckDirective,
        CtaSectionPerfectBlendComponent,
        StripHtmlPipe,
        BasicPageComponent,
        HostDirective,
        ImgUrlPipe,
        UploadComponent,
        MultiUploadComponent,
        PodcastComponent,
        HiveCardComponent,
        JobCardComponent,
        ContentComponent,
        CarouselComponent,
        PodcastSeriesComponent,
        FinalistsComponent,
        ServiceDirectoryCardComponent,
        BooksCardComponent,
        TimeSincePipe,
        ThousandTranformPipe,
        AutocompleteComponent,
        EmailShareDirective,
        IeCheckComponent,
        SingleLoginComponent,
        SearchPipe,
        BreadcrumbsComponent,
        ImgCarouselComponent,
        SelectionCardComponent,
        SubNaviComponent,
        HomeSpotlightCardComponent,
        ContentLazyLoadDirective,
        UserRegistrationComponent,
        FlickerComponent,
        AmbassadorCardComponent
    ],
    providers: [VisibilityService],
    // providers: [{
    //   provide: HammerGestureConfig,
    //   useClass: MyHammerConfig,
    // }],
    bootstrap: [],
    schemas: []
})
export class SharedModule {}
