<div class="login" [ngClass]="{ clubSuntory: isClubSuntory }">
  <mat-tab-group class="header-less-tabs" #signInTabGroup>
    <mat-tab>
      <form [formGroup]="loginForm" (ngSubmit)="signIn(loginForm)">
        <div class="row justify-content-center">
          <ng-container *ngIf="!isClubSuntory">
            <img
              class="login__logo"
              src="/assets/images/blend-swirl.png"
              alt=""
          /></ng-container>
          <ng-container *ngIf="isClubSuntory">
            <img
              class="age-gate__logo"
              src="/assets/images//ClubSuntory/small-blue.svg"
              alt=""
          /></ng-container>
        </div>
        <div class="row justify-content-center">
          <p class="login__headline" *ngIf="!isClubSuntory">
            {{ 'Auth.SignIn.WelcomeBack' | translate }}
          </p>
          <p class="cs_disclaimer--headline" *ngIf="isClubSuntory">
            Welcome Back! <br />
            Please Log in.
          </p>
          <p class="cs_disclaimer" *ngIf="isClubSuntory">
            <b>Did you know?</b> you can use your Blend credentials to log into
            club suntory
          </p>
        </div>
        <div class="row justify-content-center">
          <div class="row justify-content-center">
            <div class="tb_input">
              <input
                type="text"
                autocapitalize="off"
                autocorrect="off"
                style="text-transform: lowercase"
                autocapitalize="none"
                tabindex="1"
                matInput
                required
                class="tb_input-group__input tb_input"
                type="text"
                placeholder="{{ 'Auth.SignIn.Email' | translate }}"
                formControlName="email"
              />
            </div>
            <div class="tb_input">
              <input
                autocapitalize="off"
                autocorrect="off"
                style="text-transform: none"
                tabindex="2"
                matInput
                class="tb_input"
                required
                type="password"
                maxlength="50"
                placeholder="{{ 'Auth.SignIn.Password' | translate }}"
                formControlName="password"
              />
            </div>
          </div>
        </div>
        <div class="row justify-content-center">
          <button class="button-type" [disabled]="loginForm.invalid">
            {{ 'Auth.SignIn.Login' | translate }}
          </button>
        </div>
      </form>
      <div class="row justify-content-center">
        <p class="login__links mini" *ngIf="!ageGateForm">
          {{ 'Auth.SignIn.NoAccount' | translate }}
          <!-- No Account? -->
          <a (click)="join()"
            >{{ 'Auth.SignIn.SignUp' | translate }}
            <!-- Sign Up -->
          </a>
        </p>
        <p class="login__links mini">
          <a
            (click)="showForgotPasswordForm()"
            class="tb_link"
            href="javascript:;"
          >
            {{ 'Auth.SignIn.ForgotPasswordQuestion' | translate }}
          </a>
        </p>
        <div
          class="tb_template__section justify-content-center"
          *ngIf="msgError"
        >
          <p class="tb_type-group__subtext" [innerHTML]="message"></p>
        </div>
      </div>
    </mat-tab>
    <mat-tab class="header-less-tabs">
      <form
        class="forgotPassword"
        [formGroup]="resetForm"
        (ngSubmit)="onPasswordReset(resetForm)"
        [ngClass]="{ ageGateStyles: ageGateForm }"
      >
        <h5 class="login__headline">
          {{ 'Auth.SignIn.ForgotPassword' | translate }}
        </h5>
        <div *ngIf="!emailSent">
          <div>
            {{ 'Auth.SignIn.EnterEmail' | translate }}
          </div>

          <div class="tb_input forgotPasswordContainer">
            <input
              autocapitalize="off"
              autocorrect="off"
              tabindex="1"
              matInput
              required
              class="tb_input-group__input tb_input"
              type="text"
              placeholder="{{ 'Auth.SignIn.Email' | translate }}"
              formControlName="forgottenEmail"
            />
          </div>
          <div class="row justify-content-center">
            <button class="button-type" [disabled]="resetForm.invalid">
              {{ 'Auth.SignIn.SendEmail' | translate }}
            </button>
          </div>
        </div>
        <div class="tb_type-group" *ngIf="emailSent">
          <h2 class="tb_type-group__subtext text-center">
            {{ 'Auth.SignIn.ResetNotification' | translate }}
          </h2>
        </div>
        <div class="login__links">
          <!-- <button class="">
            <a (click)="showLoginForm()" class="link-text" href="javascript:;">
              {{ 'Auth.SignIn.BackToLogin' | translate }}
            </a>
          </button> -->
          <button (click)="showLoginForm($event)" class="button-type">
            {{ 'Auth.SignIn.BackToLogin' | translate }}
          </button>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- <mat-tab-group class="header-less-tabs" #signInTabGroup>
    <mat-tab>
        <form [formGroup]="loginForm" (ngSubmit)="signIn(loginForm)" [ngClass]="{ageGateStyles: ageGateForm}">
            <ng-container>
                <div class="tb_template noTopPadding">
                    <div class="tb_template__section noTopPadding">
                        <div class="col-12">
                            <div class="tb_type-group">
                                <h2 class="tb_type-group__subtext text-center" style="color:#171C1C;font-size: 1.2rem">
                                    {{ 'Auth.SignIn.WelcomeBack' | translate }}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Auth.SignIn.Email' | translate }}</mat-label>
                                <input autocapitalize="off" autocorrect="off" style="text-transform: lowercase"
                                    autocapitalize="none" tabindex="1" matInput required
                                    class="tb_input-group__input tb_input" type="text"
                                    placeholder="{{ 'Auth.SignIn.Email' | translate }}" formControlName="email">
                                <mat-error *ngIf="loginForm.get('email').errors?.required">
                                    {{ 'Auth.SignIn.EmailRequired' | translate }}
                                </mat-error>
                                <mat-error *ngIf="loginForm.get('email').errors?.email">
                                    {{ 'Auth.SignIn.ValidEmail' | translate }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>{{ 'Auth.SignIn.Password' | translate }}</mat-label>
                                <input autocapitalize="off" autocorrect="off" style="text-transform: none" tabindex="2"
                                    matInput class="tb_input" required type="password" maxlength="50"
                                    placeholder="{{ 'Auth.SignIn.Password' | translate }}" formControlName="password">
                                <mat-error>{{ 'Auth.SignIn.EnterPassword' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="justify-content-center" style="align-items: center;text-align: center">
                        <div>
                            <button class="tb_button"
                                [disabled]=loginForm.invalid>{{ 'Auth.SignIn.Login' | translate }}</button>
                        </div>
                        <div>
                            <p><a (click)="showForgotPasswordForm()" class="tb_link" href="javascript:;">
                                    {{ 'Auth.SignIn.ForgotPasswordQuestion' | translate }}
                                </a> </p>
                        </div>
                    </div>
                    <div class="tb_template__section justify-content-center" *ngIf="msgError">
                        <p class="tb_type-group__subtext" [innerHTML]="message"></p>
                    </div>
                </div>
            </ng-container>
        </form>

    </mat-tab>
    <mat-tab class="header-less-tabs">
        <form [formGroup]="resetForm" (ngSubmit)="onPasswordReset(resetForm)" style="text-align: center"
            [ngClass]="{ageGateStyles: ageGateForm}">
            <h5 class="tb_heading--h5" style="text-align: center">{{ 'Auth.SignIn.ForgotPassword' | translate }}</h5>
            <div *ngIf="!emailSent">
                <div>
                    {{ 'Auth.SignIn.EnterEmail' | translate }}
                </div>
                <div style="text-align: center">
                    <mat-form-field appearance="outline">
                        <mat-label>{{ 'Auth.SignIn.Email' | translate }}</mat-label>
                        <input autocapitalize="off" autocorrect="off" style="text-transform: none;" tabindex="1"
                            matInput required class="tb_input-group__input tb_input" type="text"
                            placeholder="{{ 'Auth.SignIn.Email' | translate }}" formControlName="forgottenEmail">
                        <mat-error *ngIf="resetForm.get('forgottenEmail').errors?.required">
                            {{ 'Auth.SignIn.EmailRequired' | translate }}
                        </mat-error>
                        <mat-error *ngIf="resetForm.get('forgottenEmail').errors?.email">
                            {{ 'Auth.SignIn.ValidEmail' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="row justify-content-center">
                    <button class="tb_button" [disabled]="resetForm.invalid">
                        {{ 'Auth.SignIn.SendEmail' | translate }}
                    </button>
                </div>
            </div>
            <div class="tb_type-group" *ngIf="emailSent">
                <h2 class="tb_type-group__subtext text-center" style="color:#171C1C">
                    {{ 'Auth.SignIn.ResetNotification' | translate }}
                </h2>
            </div>
            <div>
                <p>
                    <a (click)="showLoginForm()" class="tb_link" href="javascript:;">
                        {{ 'Auth.SignIn.BackToLogin' | translate }}
                    </a>
                </p>
            </div>
        </form>
    </mat-tab>
</mat-tab-group> -->
