import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { JoinService } from '@shared/user-registration/services/join.service';
@Component({
  selector: 'app-cta-section',
  templateUrl: './cta-section.component.html',
  styleUrls: ['./cta-section.component.scss'],
})
export class CtaSectionComponent implements OnInit {
  @Input() isClubSuntory: boolean;
  authenticated = false;
  constructor(
    private auth: AuthService,
    protected route: Router,
    protected localize: LocalizeRouterService,
    private joinSvc: JoinService
  ) {
    this.auth.checkAuthStatus();
    this.auth.subjectAuthenticated.subscribe((authenticated) => {
      this.authenticated = authenticated;
    });
  }

  ngOnInit() {}

  routeJoin() {
    this.joinSvc.openRegistration();
  }
}
