import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService, AuthLogEvent } from '@core/auth/auth.service';
import { CognitoService } from '@core/auth/cognito.service';
import Auth from '@aws-amplify/auth';
import { MatTabGroup } from '@angular/material/tabs';
import { DialogComponent } from '@core/components/dialog/dialog.component';
import { isPlatformBrowser } from '@angular/common';
import { ProfileService } from '@core/services/profile.service';
import { RoutingService } from '@routing/services/routing.service';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { MarketService } from '@shared/services/market.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { JoinService } from '@shared/user-registration/services/join.service';
import { ClubSuntoryService } from '@shared/services/club-suntory.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  @ViewChild('signInTabGroup') signInTabGroup: MatTabGroup;
  @Input() dialogRef: MatDialogRef<DialogComponent>;
  @Input() private loginLocation = '';
  loginForm: any;
  resetForm: any;
  userConfirm = true;
  authenticated = false;
  msgError = false;
  message = '';
  forgotPasswordActive = false;
  emailSent = false;

  logInFormBoolean = true;
  forgotPasswordBoolean = false;

  ageGateForm: boolean;

  noReRoute: boolean = false;

  private isAUSubscription: Subscription | undefined;
  isClubSuntory: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private cognito: CognitoService,
    private _snackBar: MatSnackBar,
    private profileService: ProfileService,
    private routingService: RoutingService,
    private translate: TranslateService,
    private router: Router,
    private marketSvc: MarketService,
    private localize: LocalizeRouterService,
    private _route: ActivatedRoute,
    private joinSvc: JoinService,
    private clubSuntorySvc: ClubSuntoryService,
    @Inject(PLATFORM_ID) private platform: Object
  ) { }

  ngOnInit() {

    this.clubSuntorySvc.isRelevantUrl$.subscribe((isRelevant) => {
      this.isClubSuntory = isRelevant;
    });
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      usercode: ['', Validators.nullValidator],
    });

    this.resetForm = this.formBuilder.group({
      forgottenEmail: ['', [Validators.required, Validators.email]],
    });

    if (this.loginLocation === 'age gate') {
      this.ageGateForm = true;
    } else {
      this.ageGateForm = false;
    }

    // this prevents the page from rerouting to markets home page
    if (this.router.url === '/perfect-blend/active') {
      this.noReRoute = true;
    }
  }

  showForgotPasswordForm() {
    this.signInTabGroup.selectedIndex = 1;
  }

  showLoginForm(event) {
    event.preventDefault();
    this.signInTabGroup.selectedIndex = 0;
    this.emailSent = false;
  }

  signIn(form: FormGroup) {
    const email = form.value.email ? form.value.email : form.value.email;
    this.auth.signIn(email, form.value.password).subscribe((response) => {
      if (response.success) {
        // this.auth.authSSO(form.value.password);
        this.auth.authSSO(form.value.password).subscribe((set) => { });
        // get current user to update last active status
        this.auth.getUser().subscribe((user) => { });

        this._snackBar.open(response.message, '', {
          duration: 5000,
        });

        if (this.dialogRef) {
          this.dialogRef.close(response.success);
        }

        // set country based on profile
        if (isPlatformBrowser(this.platform) && !this.noReRoute) {
          if (this._route.snapshot.params.return) {
            this.routingService.handleLogInRedirect(
              this.localize.translateRoute(this._route.snapshot.params.return)
            );
            //   } else {
            //     this.marketSvc.getMarkets(true).subscribe((markets) => {
            //       this.profileService.getProfile().subscribe((profile) => {
            //         // this.routingService.setLanguageFromAgeGate(profile.country.toLowerCase(), 'en', markets);
            //         // If user's country is different from current change market
            //         this.routingService.handleSignInMarket(
            //           profile.country,
            //           markets
            //         );
            //       });
            //     });
            //   }
            // }
          }
          else {
            this.msgError = true;
            this.message = response.message;
          }
        }
      }
    });
  }

  join() {
    this.joinSvc.openRegistration();
    this.dialogRef.close();
    // this.router.navigate([this.localize.translateRoute('/join')]);
  }

  activeForgottenPassword(event) {
    event.preventDefault();
    this.forgotPasswordActive = !this.forgotPasswordActive;
  }

  onPasswordReset(form: NgForm) {
    const email = form.value.forgottenEmail
      ? form.value.forgottenEmail.toLowerCase()
      : form.value.forgottenEmail;
    Auth.forgotPassword(email)
      .then((data) => {
        this.emailSent = true;
        // this._snackBar.open('Password reset email has been sent.', '', {
        this._snackBar.open(this.translate.instant('Auth.Reset.Success'), '', {
          duration: 5000,
          panelClass: 'tb-snackbar-info',
        });
        this.cognito.setEmail(email);
      })
      .catch((err) => {
        this.emailSent = true;
        this.auth.logAuthAction(AuthLogEvent.ResetPassword, {
          username: email,
          message: err,
        });
      });
  }
}
